.flex-row {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;

  &.wrap {
    flex-wrap: wrap;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;

  &.wrap {
    flex-wrap: wrap;
  }
}

.flex-start {
  align-items: flex-start;
}

.flex-item-start {
  align-self: flex-start;
}

.flex-end {
  align-items: flex-end;
}

.flex-item-end {
  align-self: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}
