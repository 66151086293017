.Review {
  .playback-paragraph {
    font-size: 15px;
    line-height: 40px;
  }

  .playback {
    border-radius: 50%;
    background-color: white;
    padding: 12px 12px 12px 14px;

    svg {
      position: relative;
      top: 6px;
      left: 1px;
    }
  }

  .image-responsive img {
    max-width: 50%;
  }

  .technicalDetails {
    display: flex;
    flex: 1;

    > div {
      padding: 20% 15%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }

  .mainParagraph {
    padding: 40px 0 40px 10%;
  }

  .bottom-padding {
    padding-bottom: 5%;
  }
}
