.Banner {
  background-image: url("../../../images/Banner_Home.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .title {
    font-size: 7em;
    color: white;
  }

  @media screen and (max-width: 480px) {
    .title {
      font-size: 5em;
    }
  }

  @media screen and (max-width: 360px) {
    .title {
      font-size: 4em;
    }
  }
}
