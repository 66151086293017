@import "../../../variables";

.Filters {
  padding: 56px 0 0 0;

  ul {
    list-style-type: none;
    justify-content: center;
  }

  li {
    text-align: center;
    margin-bottom: 25px;

    span {
      cursor: pointer;

      &.active,
      &:hover {
        box-shadow: 0 3px 1px -2px $black;
      }
    }
  }
}
