.About {
  .file-download-anchor {
    margin: 50px 0;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    > span {
      padding-left: 9px;
      color: inherit;
    }
  }
}
