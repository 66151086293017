@import "../../../../variables";

.WorkBox {
  padding: 25px;

  .box {
    padding: 25px 25px 0 25px;
    border-radius: 20px;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }

    span.subject {
      color: white;
      font-size: 1em;
    }

    h2 {
      &.title.with-subject {
        color: white;
        cursor: pointer;
        margin-bottom: 0;
      }

      a {
        color: inherit;
      }
    }
  }

  .media-size {
    background-size: "60%";
  }

  @media screen and (max-width: 1240px) {
    .media-size {
      background-size: 70%;
    }
  }
}
