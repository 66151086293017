.Footer {
  .footer-columns > div > div,
  .footer-paragraph {
    padding-right: 30px;
  }

  .footer-columns h3 {
    letter-spacing: 0;
  }

  .section-padding {
    padding: 5% 5% 0 5%;
  }

  .row-displayed {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    img {
      padding: 5px;
    }
  }

  .icon-items {
    .fa-facebook,
    .fa-instagram {
      margin-right: 40px;
    }

    i {
      font-size: 1.4em;
    }
  }

  ul {
    padding: 0;

    li {
      list-style: none;
      letter-spacing: 0;
      margin-bottom: 8px;

      a {
        color: inherit;
      }
    }
  }
}
