@import "./fonts/fonts.scss";
@import "./layouts";

h1 {
  font-size: 3em;

  &.with-subject {
    margin-top: 0;
  }
}

h2 {
  font-size: 2em;

  &.with-subject {
    margin-top: 0;
  }
}

h3 {
  font-size: 1.5em;
}

p {
  font-size: 1em;
  line-height: 1.5em;
  letter-spacing: 0;

  &.small {
    font-size: 0.8em;
  }
}

a {
  text-decoration: none;
}

.small-size {
  font-size: 0.8em;
}

button {
  background: none;
  border: none;
  padding: 0;

  &.link-btn {
    font-family: $poppins;
    font-weight: 600;

    a {
      color: inherit;
    }
  }
}

span.subject {
  font-size: 0.9em;
  letter-spacing: 1px;
}

.title {
  font-family: $yeseva;
  font-weight: 400;
}

.subtitle {
  font-family: $poppins;
  font-weight: 600;

  i,
  .italic {
    font-family: $poppins;
    font-style: italic;
  }
}

.subject {
  font-family: $poppins;
  font-weight: 600;
}

.content {
  font-family: $poppins;
  font-weight: 400;

  i,
  .italic {
    font-family: $poppins;
    font-weight: 400;
    font-style: italic;
  }
}

.bold-font {
  font-weight: bold;
}

.justified {
  text-align: justify;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.no-margin {
  margin: 0;
}

.empty-space {
  height: 3em;
}

.inner-gap > * {
  margin-bottom: 10%;
}

.image-box {
  max-width: 100%;
}

.s1em {
  font-size: 1em;
}

.underlined {
  text-decoration: underline;
}

.section-padding {
  padding: 8% 10%;
}

.mid-section-padding {
  padding: 0 5%;
}

.section-margin {
  margin: 10% 0;
}

.image-responsive {
  max-width: 100%;
  max-height: 100%;
}
