@import "globals.scss";

$black: #1f2532;
$orange: #fc643f;
$light-blue: #8ab9dd;
$green: #06c48c;
$yellow: #ffd05d;
$pink: #ff657d;
$magenta: #cb1d5b;
$pale-green: #648f85;

.white,
.white-review .review-title {
  color: white;
}

.white-bg {
  background-color: white;
}

.black,
.black-review .review-title {
  color: $black;

  &.bottom-shadow {
    box-shadow: 0 4px 0 -2px $black;
  }
}

.black-bg {
  background-color: $black;
}

.orange,
.orange-review .review-title {
  color: $orange;
}

.orange-bg {
  background-color: $orange;
}

.light-blue,
.light-blue-review .review-title {
  color: $light-blue;
}

.light-blue-bg {
  background-color: $light-blue;
}

.green,
.green-review .review-title {
  color: $green;
}

.green-bg {
  background-color: $green;
}

.yellow,
a.yellow-link,
.yellow-review .review-title {
  color: $yellow;

  &.bottom-shadow {
    box-shadow: 0 4px 0 -2px $yellow;
  }

  .no-decoration {
    text-decoration: none;
  }
}

.yellow-bg {
  background-color: $yellow;
}

.pink,
.pink-review .review-title {
  color: $pink;
}

.pink-bg {
  background-color: $pink;
}

.magenta,
.magenta-review .review-title {
  color: $magenta;
}

.magenta-bg {
  background-color: $magenta;
}

.pale-green,
.pale-green-review .review-title {
  color: $pale-green;
}

.pale-green-bg {
  background-color: $pale-green;
}
